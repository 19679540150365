import { createSlice } from "@reduxjs/toolkit";

import { getGroupProducts, gropuListing, groupDetails } from "../actions/adminactions";

const initialState = {
  alldata: null,
  groupDetails: null,
  deactivateAcc:null,
  pagenumber:null,
  groupProducts:null
};

export const groupSlice = createSlice({
  name: "groupSlice",
  initialState,
  reducers: {
    groupPageNumber:(state,action)=>{
      state.pagenumber = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(gropuListing.fulfilled, (state, action) => {
      state.alldata = action.payload;
    });
    builder.addCase(groupDetails.fulfilled, (state, action) => {
      state.groupDetails = action.payload;
    });
    builder.addCase(getGroupProducts.fulfilled, (state, action) => {
      state.groupProducts = action.payload;
    });
   
  },
});

export const {groupPageNumber} = groupSlice.actions

export default groupSlice.reducer;
