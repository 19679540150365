import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alluserpath: null,
  userpaths: null,
  influencerpaths: null,
  alluserpage: null,
  userpage: null,
  influencerpage: null,
  dashboardData: null,
  alluserlength: null,
  influencerlength: null,
  userlength: null,
};

export const usermgmtSlice = createSlice({
  name: "mgmtSlice",
  initialState,
  reducers: {
    allusertpath: (state, action) => {
      state.alluserpath = action.payload;
    },
    userpath: (state, action) => {
      state.userpaths = action.payload;
    },
    influencerpath: (state, action) => {
      state.influencerpaths = action.payload;
    },
    alluserpgnumber: (state, action) => {
      state.alluserpage = action.payload;
    },
    userpgnumber: (state, action) => {
      state.userpage = action.payload;
    },
    influencerpgnumber: (state, action) => {
      state.influencerpage = action.payload;
    },
    alluser: (state, action) => {
      state.alluserlength = action.payload;
    },
    influencerslength: (state, action) => {
      state.influencerlength = action.payload;
    },
    userslength: (state, action) => {
      state.userlength = action.payload;
    },
  },
});

export const {
  allusertpath,
  userpath,
  influencerpath,
  alluserpgnumber,
  userpgnumber,
  influencerpgnumber,
  alluser,
  influencerslength,
  userslength,
} = usermgmtSlice.actions;

export default usermgmtSlice.reducer;
