import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";

import Support from "./pages/Support";
import Administrators from "./pages/Administrators";

import Dashboard from "./pages/Dashboard";

import Supportchat from "./pages/SupportChat";
import CreateAdmin from "./pages/CreateAdmin";
import AddCategory from "./pages/AddCategory";

import Users from "./pages/Users";
import Influencers from "./pages/Influencers";

import Groups from "./pages/Groups";
import ReportDetails from "./pages/ReportDetails";
import UserProfile from "./pages/UserProfile";
import ManageMerchant from "./pages/ManageMerchant";
import ManageWallet from "./pages/ManageWallet";
import AddServices from "./pages/AddServices";
import UserVerification from "./pages/UserVerification";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";

import ManageWalletInfluencers from "./pages/ManageWalletInfluencers";
import Report from "./pages/Report";
import ManageMerchView from "./pages/ManageMerchView";
import ForgotPasswordTwo from "./pages/ForgotPasswordtwo";
import GroupView from "./pages/GroupView";
import MerchView from "./pages/MerchView";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PostShareAdmin from "./pages/PostshareAdmin";
import ManageAdvertisment from "./pages/ManageAdvertisment";
import CreateAdvertisment from "./pages/CreateAdvertisment";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/sharepost/:id" element={<PostShareAdmin />} />
          
          <Route element={<ProtectedRoutes />}>
            <Route path="/Otp" element={<LoginOtp />} />
            <Route path="/Forgot-Password" element={<ForgotPassword />} />
            <Route path="/UserManagement" element={<UserManagement />} />

            <Route path="/Support" element={<Support />} />
            <Route path="/Administrators" element={<Administrators />} />

            <Route path="/Dashboard" element={<Dashboard />} />

            <Route path="/UserProfile" element={<UserProfile />} />

            <Route path="/Supportchat/:id" element={<Supportchat />} />
            <Route path="/Create-Admin" element={<CreateAdmin />} />
            <Route path="/addcategory" element={<AddCategory />} />

            <Route path="/Users" element={<Users />} />

            <Route path="/influencers" element={<Influencers />} />

            <Route path="/groups" element={<Groups />} />
            <Route path="/GroupView/:id" element={<GroupView />} />

            {/* <Route path="/managemerchant" element={<ManageMerchant />} /> */}
            <Route path="/MerchView/:id" element={<MerchView />} />
            <Route path="/UserVerification" element={<UserVerification />} />

            <Route path="/managewallet" element={<ManageWallet />} />
            <Route path="/AddServices" element={<AddServices />} />
            <Route path="/AddServices" element={<AddServices />} />

            <Route path="/ReportDetails/:id" element={<ReportDetails />} />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/AddNotification" element={<AddNotification />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />

            <Route
              path="/managewalletinfluencers"
              element={<ManageWalletInfluencers />}
            />
            <Route path="/report" element={<Report />} />
            <Route path="/managemerchview" element={<ManageMerchView />} />
            <Route path="/change-password" element={<ForgotPasswordTwo />} />
            <Route path="/ManageAdvertisment" element={<ManageAdvertisment />} />
            <Route path="/CreateAdvertisment" element={<CreateAdvertisment />} />
          </Route>
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
