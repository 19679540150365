import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Await, Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { resetAdminPassword } from "../redux/actions/adminactions";

export default function ForgotPasswordTwo() {
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confrmpass, setconfrmpass] = useState(null);
  const [forgetPass, setforgetPass] = useState({
    password: "",
    email: location?.state?.email,
  });
  const [cferror, setCferror] = useState("");

  const [error, setError] = useState({
    passworderr: "",
  });

  const handlechange = (e) => {
    setforgetPass((old) => ({ ...old, [e.target.name]: e.target.value }));
    setError((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };
  const handleconfirm = (e) => {
    setconfrmpass(e.target.value);
    setCferror("");
  };

  const handleclick = async () => {
    console.log(forgetPass);
    console.log(confrmpass);

    let err = {
      passworderr: "",
    };
    let isvalid = true;
    if (!forgetPass.password) {
      err.passworderr = "Enter new Password";
      isvalid = false;
    }
    if (!confrmpass) {
      setCferror("Enter confirm Password");
      isvalid = false;
    }

    if (!isvalid) {
      setError(err);
    }
    if (isvalid) {
      
      if (forgetPass.password === confrmpass) {

        try {
          let data = await dispatch(resetAdminPassword(forgetPass));
          console.log(data);
          if (data?.payload?.status_code === 200) {
            toast.success(data?.payload?.message)
            navigate("/");
          } else {
            toast.error(data?.payload?.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Password must be same");
      }
    }
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Enter New Password</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Enter Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={forgetPass.password}
                        onChange={handlechange}
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">
                        {error?.passworderr}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confrmpass"
                        onChange={handleconfirm}
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">{cferror}</Form.Label>
                    </Form.Group>
                  </div>
                  <div
                    // to="/"
                    onClick={handleclick}
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Log In
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
