import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotification,
  notifications,
} from "../redux/actions/adminactions";
import Pagination from "../Components/Layout/Pagination";

export default function Notification() {
  const dispatch = useDispatch();
  const [showd, setshowd] = useState(false);
  const [pagination, setpagination] = useState(null);
  const [flag, setflag] = useState(true);
  const [Id, setId] = useState(null);
  const alldata = useSelector((state) => state.notificationData.data);

  const [userMgmtParams, setUserMgmtParams] = useState({
    page: "1",
    search: "",
    limit: "10",
  });

  useEffect(() => {
    dispatch(notifications(userMgmtParams));
  }, [flag, userMgmtParams]);

  useEffect(() => {
    setpagination(alldata?.data?.totalPages);
  }, [alldata]);

  const handleshowd = (id) => {
    setId(id);
    setshowd(true);
  };

  const handledelete = () => {
    dispatch(deleteNotification({ id: Id })).then((res) => {
      if (res?.payload?.success) {
        setshowd(false);
        setflag(!flag);
      }
    });
  };

  const handlePages = (page) => {
    // dispatch(alluserpgnumber(page));

    setUserMgmtParams((old) => ({ ...old, page: page }));
  };

  const handlesearch = (e) => {
    setUserMgmtParams((old) => ({ ...old, search: e.target.value }));
  };

  const handlechange = (e) => {
    setUserMgmtParams((old) => ({ ...old, limit: e.target.value }));
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Notifications</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={6} md={8}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    name="search"
                    value={userMgmtParams.search}
                    onChange={handlesearch}
                    placeholder="Keyword Search.."
                  />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={6}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/AddNotification">Add Notification</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (userMgmtParams?.page - 1) * userMgmtParams?.limit}{" "}
                -{" "}
                {alldata?.data?.notifications?.length +
                  (userMgmtParams?.page - 1) * userMgmtParams.limit}{" "}
                of {alldata?.data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlechange}
                  name="limit"
                  value={userMgmtParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>Description</th>
                <th>To</th>
                <th>Action </th>
              </tr>
            </thead>
            <tbody>
              {alldata?.data?.notifications?.map((item, index) => {
                const serialNumber =
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit +
                  index +
                  1;

                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{item?.title}</td>
                    <td>{item?.desc}</td>
                    <td>
                      {item?.to === 0
                        ? "All"
                        : item?.to === 1
                        ? "User"
                        : "Influencers"}
                    </td>
                    <td>
                      <Button
                        onClick={() => handleshowd(item?._id)}
                        className="account-btn"
                      >
                        DELETE
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })} 
            </tbody>
          </Table>
         
            {alldata?.data?.notifications?.length == 0 && (
              <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
              </div>
            )}
         
        </div>
        
      </Container>

      <Modal show={showd} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">Are You Sure You Want To Delete This Notification</h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button  className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
          <Button  className="theme-btn" onClick={handledelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination
        class="next-btn-new"
        pagination={pagination}
        paginationevent={handlePages}
      />


{/* <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            {profileData?.userData?.is_active === false
              ? "Are You Sure You Want To Activate This Account"
              : "Are You Sure You Want To Deactivate This Account"}
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleclick}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Layout>
  );
}
