import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
// import { getReports } from "../Redux/Actions/Adminactions";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import { Button } from "react-bootstrap";
import {
  activateAds,
  addsList,
  deleteadd,
} from "../redux/actions/adminactions";
import { toast } from "react-toastify";

export default function ManageAdvertisment() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.adsData.data);
  // console.log(data);
  const navigate = useNavigate();
  const [pages, setPages] = useState("1");
  const [limit, setlimit] = useState(10);
  const [search, setSearch] = useState("");
  const [flag, setflag] = useState(true);
  const [data, setData] = useState(null);
  const [pagination, setpagination] = useState(null);

  useEffect(() => {
    const fetchdata = async () => {
      let data = await dispatch(
        addsList({ page: pages, limit: limit, search: search })
      );
      setData(data);
      setpagination(data?.payload?.data?.totalPages);
    };

    fetchdata();
  }, [flag, pages, limit, search]);

  console.log(data);

  const handlePages = (page) => {
    // dispatch(alluserpgnumber(page));

    setPages(page);
  };

  const fetchDataAndExportToExcel = async () => {
    try {
      // const response = await dispatch(getReports());
      // console.log(response);
      // const allData = response?.payload?.message
      // console.log(allData)
      // const ws = XLSX.utils.json_to_sheet(allData);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
      // XLSX.writeFile(wb, "exported_data.xlsx", {
      //   bookType: "xlsx",
      //   bookSST: false,
      //   type: "blob",
      // });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Advertisment</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/CreateAdvertisment">Create</Link>{" "}
                </div>
                {/* <div className="cmn-btn">
                  <Link to="/ReportDetails">Report</Link>{" "}
                </div> */}
              </Col>
            </Row>
          </div>
        </Container>
        {/* <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Administrators" className="active-tab">
                  ALL
                </Link>
              </li>
              <li>
                <Link to="#">Super Admin 2</Link>
              </li>
              <li>
                <Link to="#">Accounts 1</Link>
              </li>
            </ul>
          </div>
        </Container> */}
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pages - 1) * limit} -{" "}
                {data?.payload?.data?.allAds?.length + (pages - 1) * limit} of{" "}
                {data?.payload?.data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  name="limit"
                  value={limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>Image</th>
                <th>Link</th>
                <th style={{textAlign:"center"}}>Action</th>
                {/* <th>Status</th>  */}
              </tr>
            </thead>
            <tbody>
              {data?.payload?.data?.allAds?.map((user, index) => {
                return (
                  <tr>
                    <td>{(pages - 1) * limit + index + 1}</td>
                    <td>{user?.title}</td>
                    <td className="user-img">
                      {user?.image_video?.split(".")[1] == "mp4" ? (
                        <video
                          style={{
                            width: "50px",
                            borderRadius: "70px",
                            height: "50px",
                            objectFit: "cover",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                        >
                          <source src={`${BASE_URL}${user?.image}`}></source>
                        </video>
                      ) : (
                        <img src={`${BASE_URL}${user?.image}`} />
                      )}
                    </td>
                    <td>{user?.link}</td>
                    <td  className="cmn-btn">
                      <div  style={{display:"flex",gap:"10px",justifyContent:"center"}}>
                        <Link
                          onClick={() => {
                            dispatch(
                              deleteadd({ advertisementId: user?._id })
                            ).then((res) => {
                              if (res?.payload?.success) {
                                // toast.success(res?.payload?.message);
                                setflag(!flag);
                              }
                            });
                          }}
                          className="cmn-btn"
                          style={{ color: "white" }}
                        >
                          Delete
                        </Link>
                        <Link
                          onClick={() => {
                            dispatch(
                              activateAds({
                                advertisementId: user?._id,
                                type: "1",
                              })
                            ).then((res) => {
                              if (res?.payload?.success) {
                                // toast.success(res?.payload?.message);
                                setflag(!flag);
                              }
                            });
                          }}
                          className="cmn-btn"
                          style={{ color: "white" }}
                        >
                          {user?.is_active == 1 ? "Activated" : "Activate"}
                        </Link>
                        <Link
                          onClick={() => {
                            dispatch(
                              activateAds({
                                advertisementId: user?._id,
                                type: "0",
                              })
                            ).then((res) => {
                              if (res?.payload?.success) {
                                // toast.success(res?.payload?.message);
                                setflag(!flag);
                              }
                            });
                          }}
                          className="cmn-btn"
                          style={{ color: "white" }}
                        >
                          {user?.is_active == 1 ? "Deactivate" : "Deactivated"}
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {data?.payload?.data?.allAds?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        class="next-btn-new"
        pagination={pagination}
        paginationevent={handlePages}
      />
    </Layout>
  );
}
