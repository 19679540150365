import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { manageReport, reportsData } from "../redux/actions/adminactions";
import Pagination from "../Components/Layout/Pagination";

export default function Report() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reportsData.data);
  console.log(data, "sdfsdf");
  const [pagination, setpagination] = useState(null);
  const [userMgmtParams, setUserMgmtParams] = useState({
    page: "1",
    search: "",
    limit: "10",
  });
  const [flag,setflag] = useState(true)

  useEffect(() => {
    dispatch(reportsData(userMgmtParams)).then((res) => {
      if (res) {
        setpagination(res?.payload?.totalPages);
      }
    });
  }, [userMgmtParams,flag]);

  const handlePages = (page) => {
    // dispatch(userpgnumber(page));

    setUserMgmtParams((old) => ({ ...old, page: page }));
  };

  const handlechange = (e) => {
    setUserMgmtParams((old) => ({ ...old, limit: e.target.value }));
  };

  const handlesearch = (e) => {
    setUserMgmtParams((old) => ({ ...old, search: e.target.value }));
  };

  const hadlestatus = (e,id) => {
    
    dispatch(manageReport({
      reportId:id,
      type:e.target.value

    })).then(res=>{
      console.log(res)
      if(res?.payload?.success){
        setflag(!flag)
      }
    })
  };

  console.log(data?.users?.length)

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Report</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              {/* <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    name="search"
                    value={userMgmtParams.search}
                    onChange={handlesearch}
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col> */}
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 +
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit}{" "}
                -{" "}
                {data?.users?.length +
                  (userMgmtParams?.page - 1) * userMgmtParams.limit}{" "}
                of {data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlechange}
                  name="limit"
                  value={userMgmtParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Report By </th>
                <th>Report To </th>
                <th>Reason</th>
                <th>Description </th>
                <th>Status </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.users?.map((item, index) => {
                const serialNumber =
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit +
                  index +
                  1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{item?.reportByUser?.fullName || "N/A"}</td>
                    <td>
                      <Link to={`/ReportDetails/${item?._id}`}>
                        <b>{item?.reportedUser?.fullName}</b>
                      </Link>
                    </td>
                    <td>
                      {" "}
                      {item?.reasonType == 0
                        ? "Suspect"
                        : item?.reasonType == 1
                        ? "Harassment"
                        : item?.reasonType == 2
                        ? "Stalking"
                        : item?.reasonType == 3
                        ? "Fake Account"
                        : "Other"}
                    </td>
                    <td>{item?.comment}</td>
                    <td>{item?.status === 0 ? "Open" : "Resolve"}</td>
                    <td>
                      <div className="select-box">
                        <Form.Select
                          value={item?.status}
                          onChange={(e)=>hadlestatus(e,item?._id)}
                          aria-label="Default select example"
                        >
                          <option value="1">Reslove</option>
                          <option value="0">Open</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.data?.users?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>
      <Pagination pagination={pagination} paginationevent={handlePages} />
    </Layout>
  );
}
