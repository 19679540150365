import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getDataByParams, userMgmt } from "../redux/actions/adminactions";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import {
  userpath,
  userpgnumber,
  userslength,
} from "../redux/reducers/userMgmtSlice";

export default function ManageWallet() {
  const influencerlength = useSelector(
    (state) => state?.usermgmtData?.influencerlength
  );

  const dispatch = useDispatch();
  const [flag, setFlag] = useState(true);
  const navigate = useNavigate();
  const [pagination, setpagination] = useState(null);
  const [userMgmtParams, setUserMgmtParams] = useState({
    page: "1",
    search: "",
    limit: "10",
    role: "0",
    type: "",
  });
  const [AllUsers, setAllUsers] = useState(null);
  const location = useLocation();
  console.log(location?.pathname);

  useEffect(() => {
    dispatch(userpath(location?.pathname));
  }, [location?.pathname]);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let data = await dispatch(getDataByParams(userMgmtParams));

        dispatch(userslength(data?.payload?.totalCount));
        setAllUsers(data);

        setpagination(data?.payload?.totalPages);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, [userMgmtParams]);
  const handlePages = (page) => {
    dispatch(userpgnumber(page));

    setUserMgmtParams((old) => ({ ...old, page: page }));
  };

  const handlechange = (e) => {
    setUserMgmtParams((old) => ({ ...old, limit: e.target.value }));
  };

  const handlesearch = (e) => {
    setUserMgmtParams((old) => ({ ...old, search: e.target.value }));
  };
  const handleclick = (id) => {
    navigate("/UserProfile", {
      state: {
        id: id,
        // path: pathfromredux,
      },
    });
  };

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(userMgmt());
      console.log(response?.payload?.users);

      const allData = response?.payload?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  const handleselect = (e) => {
    setUserMgmtParams((old) => ({ ...old, type: e.target.value }));
  };

  console.log(AllUsers);

  
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Wallet</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    name="search"
                    value={userMgmtParams.search}
                    onChange={handlesearch}
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Select
                  value={userMgmtParams.type}
                  onChange={handleselect}
                  aria-label="Default select example"
                >
                  <option>All</option>
                  <option value="1">Active</option>
                  <option value="0">Temporary banned</option>
                </Form.Select>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
              <Link to="/managewallet" className="active-tab">
                  Users <span> </span>
                </Link>
              </li>
              <li>
                <Link to="/managewalletinfluencers">
                Influencers <span></span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
          <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 +
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit}{" "}
                -{" "}
                {AllUsers?.payload?.users?.length +
                  (userMgmtParams?.page - 1) * userMgmtParams.limit}{" "}
                of {AllUsers?.payload?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
            <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlechange}
                  name="limit"
                  value={userMgmtParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>User Name </th>
                <th>Full Name </th>
                <th>Email </th>
                <th>Total Viscuits </th>
                <th>Total Viscuits Purchased </th>
              </tr>
            </thead>
            <tbody>
            {AllUsers?.payload?.users?.map((item, index) => {
                const serialNumber =
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit +
                  index +
                  1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td
                      // style={{ cursor: "pointer" }}
                      // onClick={() => handleclick(item?._id)}
                      //  to="/UserProfile"
                    >
                      {item?.userName}
                    </td>
                    <td>{item?.fullName}</td>
                    <td>{item?.email}</td>
                    <td>{item?.remaining_viscuit}</td>
                    <td className="select-box">
                      {/* <Form.Select
                        onChange={() => handleselect(item?._id)}
                        value={item?.is_active === false ? "false" : "true"}
                        aria-label="Default select example"
                      >
                        <option value="false">Temporary Banned</option>
                        <option value="true">Active</option>
                      </Form.Select> */}
                      {item?.total_viscuit}
                    </td>
                  </tr>
                );
              })}
              
            </tbody>
          </Table>
          
          {AllUsers?.payload?.users?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>
      <Pagination pagination={pagination} paginationevent={handlePages} />
    </Layout>
  );
}
