import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { forgetAdminPassword } from "../redux/actions/adminactions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export default function ForgotPassword() {

  const [email,setEmail] = useState(null)
  const [error,setError] = useState(null)
  const navigate = useNavigate()
  const dispatch=useDispatch()

  const handlechange=(e)=>{
    setEmail(e.target.value)
    setError("")

  }

  const handleclick=async()=>{
    let isvalid=true
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!email){
     setError("Enter email address")
     isvalid=false
    }else if(!emailRegex.test(email)){
      setError("Enter valid email")
      isvalid=false
    }
    if(isvalid){
     try{
      let data = await dispatch(forgetAdminPassword({email:email}))
        if(data?.payload?.status_code===200){
          toast.success(data?.payload?.message)
          navigate("/otp",{state:{
            email:email
          }})
        }else{
          toast.error(data?.payload?.message)
        }
      }catch(error){
        console.log(error)
      }
     }
    }
  

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Forgot Password</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control name="email" onChange={handlechange} type="email" placeholder="Type Here" />
                      <Form.Label className="text-danger">{error}</Form.Label>
                    </Form.Group>
                  </div>
                  <div
                    // to="/otp"
                    style={{cursor:"pointer"}}
                    onClick={handleclick}
                    variant="primary"
                    // type="submit"
                    className="submit"
                  >
                    Send OTP
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
