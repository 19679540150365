import { createAsyncThunk } from "@reduxjs/toolkit";
import adminapi from "../../services/adminapi";


const adminLogin = createAsyncThunk("adminLogin",async(details)=>{
    const {data} = await adminapi.post("/auth/login",details)
    return data

})

export {adminLogin}