import { createSlice } from "@reduxjs/toolkit";
import { notifications } from "../actions/adminactions";

const initialState={
    data:''
}


export const NotificationSlice=createSlice({
    name:"notification",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(notifications.fulfilled,(state,action)=>{
              state.data =action.payload
        })
    }


})

export default NotificationSlice.reducer