import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function AddCategory() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Add Category</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/ManageProperties">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8} className="mb-4">
            <div className="customer-form-new mt-4">
              <div className="customer-form-inner">
                <Row>
                  {/* <Col lg={12}>
                    <Form.Group className="mb-3 image-upload-main">
                      <Form.Label>Property Image</Form.Label>
                      <div className="input-image-show">
                        <Form.Control type="file" placeholder="Upload Image" />

                        <p>Upload Image</p>
                      </div>
                    </Form.Group>
                  </Col> */}
                  <Col lg={12}>
                    <Form.Group className="mb-4">
                      <Form.Label>Category name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Add Title..........."
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group className="mb-4">
                      <div className="d-flex justify-content-between mb-2">
                        <Form.Label>Add Category Sizes</Form.Label>
                        <button className="add-cat-btn" type="button">
                          <svg
                            width="23"
                            height="24"
                            viewBox="0 0 23 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="11.505"
                              cy="11.6179"
                              r="11.451"
                              fill="black"
                            />
                            <rect
                              x="10.8057"
                              y="3.87817"
                              width="1.28857"
                              height="15.4647"
                              rx="0.644287"
                              fill="#D9D9D9"
                            />
                            <rect
                              x="19.1821"
                              y="10.9662"
                              width="1.28857"
                              height="15.4647"
                              rx="0.644287"
                              transform="rotate(90 19.1821 10.9662)"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </button>
                      </div>
                      <Form.Control type="text" placeholder="Type Here" />
                    </Form.Group>
                  </Col>

                  <Col lg={12} className="d-flex justify-content-center mt-2">
                    <button type="button" className="add-btn">
                      Add
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
