import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import Users from "../Components/Layout/elements/ChartUsers";
import Orders from "../Components/Layout/elements/ChartOrders";
import { useDispatch, useSelector } from "react-redux";
import { dashboard, getDataByParams } from "../redux/actions/adminactions";

export default function Dashboard() {
  const dispatch=useDispatch()
  const data=useSelector((state)=>state.dashboardData.allData)
  
  
      useEffect(()=>{
        dispatch(dashboard())

      },[])
  

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Dashboard</h2>
        </div>
      </div>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                {/* <div className="d-flex justify-content-between align-items-center">
                  <h2>REVENUE OVERVIEW</h2>
                </div> */}
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <h6>Total Users</h6>
                      <h4>{data?.data?.totalUsers}</h4>
                      <p>1000 - Average users per day</p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <h6>Total Influencers </h6>
                      <h4>{data?.data?.totalInfluencers}</h4>
                      <p>1000 - Average users per day</p>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <h6>Total Influencer Groups </h6>
                      <h4>{data?.data?.totalGroups}</h4>
                      <p>1000 - Average users per day </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            {/* <Col xxl={3} xl={4} lg={3}>
                  <div className="dashbox-side">
                    <h2>ACCOUNTS OVERVIEW</h2>
                    <div className="dash-inner-boxes">
                      <h6>ACCOUNT CURRENT </h6>
                      <h4>£2,202,330.00</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
          </Row>

          <Row className="dash-bottom mb-4">
            <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph">
                <h6>Users</h6>
                <Users />
              </div>
            </Col>
            {/* <Col xxl={4} xl={4} lg={4}>
                  <div className="dashbox-side">
                    <h2>MEMBERS </h2>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL MEMBERS </h6>
                      <h4>10,292</h4>
                      <p>10% increase from last month</p>
                    </div>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL CLUBS </h6>
                      <h4>761</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
            <Col xxl={6} xl={6} lg={6}>
              <div className="dash-graph ">
                <h6>Total Orders</h6>
                <Orders />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
