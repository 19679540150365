import { configureStore } from "@reduxjs/toolkit";
import { usermgmtSlice } from "./reducers/userMgmtSlice";
import { groupSlice } from "./reducers/GroupSlice";
import { dashboardSlice } from "./reducers/DashboardSlice";
import {NotificationSlice} from "./reducers/NotificationSlice";
import { reportSlice } from "./reducers/ReportSlice";
import { supportSlice } from "./reducers/SupportSlice";
// import {adsSlice} from "./reducers/adsSlice";







export const store = configureStore({
  reducer: {
    usermgmtData:usermgmtSlice.reducer,
    dashboardData:dashboardSlice.reducer, 
    groupdata : groupSlice.reducer,
    notificationData : NotificationSlice.reducer,
    reportsData : reportSlice.reducer,
    supportData : supportSlice.reducer,
    // adsData:adsSlice.reducer

  },
});