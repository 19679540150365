import { createSlice } from "@reduxjs/toolkit";
import { reportsData, reportsDatabyId } from "../actions/adminactions";

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState: {
    data: "",
    databyid: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(reportsData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(reportsDatabyId.fulfilled, (state, action) => {
      state.databyid = action.payload;
    });
  },
});

export default reportSlice.reducer;
