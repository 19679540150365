import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  deactivateUser,
  deleteuserProfile,
  userProfile,
} from "../redux/actions/adminactions";
import { toast } from "react-toastify";

export default function UserProfile() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [profileData, setProfileData] = useState(null);
  const [path, setPath] = useState();
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true);
  const [show, setshow] = useState(false);
  const [showd, setshowd] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  console.log(selectedImage, "image");
  console.log(profileData?.userPosts[selectedImage]?.image, "hsagfjhd");

  useEffect(() => {
    setPath(location?.state?.path);
  }, [location?.state?.path]);

  console.log(profileData, "uyuy");

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let data = await dispatch(userProfile({ userId: location?.state?.id }));
        setProfileData(data?.payload?.data);
        // setProfileData(data?.payload?.message);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, [location?.state?.id, flag]);

  const handledelete = async () => {
    try {
      let data = await dispatch(
        deleteuserProfile({ userId: profileData?.userData?._id })
      );
      if (data?.payload?.status_code === 200) {
        toast.success(data?.payload?.message);
        navigate("/UserManagement");
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleclick = async () => {
    try {
      let data = await dispatch(
        deactivateUser({ userId: profileData?.userData?._id })
      );
      if (data?.payload?.status_code === 200) {
        toast.success(data?.payload?.message);
        // navigate("/UserManagement");
        setFlag(!flag);
        setshow(false);
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleshow = () => {
    setshow(true);
  };

  const openLightbox = (index) => {
    setSelectedImage(index);
  };
  console.log(selectedImage);

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  console.log(profileData?.userData?.is_active);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>
              {profileData?.userData?.Role === 1
                ? "Influencer Profile "
                : "User Profile"}
            </h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link // to="/userManagement"
                  to={path}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main h-100">
                  <Row>
                    <Col lg={6}>
                      <Figure className="d-flex">
                        <div>
                          <Figure.Image
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              profileData?.userData?.profileImage
                                ? `https://vistalk-api.bosselt.com/${profileData?.userData?.profileImage}`
                                : require("../Assets/Images/Logo.svg").default
                            }
                          />
                          <div className="status-show">
                            {profileData?.userData?.is_active === true
                              ? "Active"
                              : "In-Active"}
                          </div>
                        </div>
                        <div>
                          <Figure.Caption>
                            <h2>{profileData?.userData?.userName}</h2>

                            {/* <p>
                              <span>Email - </span>
                              22 Shelton Street, London SW6
                            </p> */}
                            {/* <p className="mb-3">
                              <span>Phone - </span> 
                              United Kingdom
                            </p> */}
                            <p>{profileData?.userData?.email}</p>
                            <p>{profileData?.userData?.countryCode} {profileData?.userData?.phoneNumber}</p>
                            {/* <Link to="#" className="text-black">
                              www.domain.com
                            </Link> */}
                          </Figure.Caption>
                        </div>
                      </Figure>
                    </Col>
                    <Col lg={6}>
                      <div className="total-follow">
                        <h3>Total Followers </h3>
                        <p>{profileData?.userData?.followers?.length || "0"}</p>
                      </div>
                      {profileData?.userData?.Role === 0 ? (
                        <div className="total-follow">
                          <h3>Total Following </h3>
                          <p>
                            {profileData?.userData?.following?.length || "0"}
                          </p>
                        </div>
                      ) : null}
                    </Col>

                    <hr />

                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Bio</h2>
                        <Figure.Caption className="m-0 mt-4">
                          <p>
                            {profileData?.userData?.bioData || (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p className="mb-5">No data available yet</p>
                              </div>
                            )}
                          </p>
                        </Figure.Caption>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <div className="user-profile-main h-100">
                  <h2 className="property">Posts</h2>
                  <div className="post-inner">
                    <Row>
                      {/* <Col lg={4}>
                        <div className="post-box">
                          <img src={require("../Assets/Images/image1.png")} />
                        </div>
                      </Col> */}
                      {profileData?.userPosts?.map((item, index) => {
                        return (
                          <Col lg={4}>
                            <div className="post-box">
                              <img
                                onClick={() => openLightbox(index)}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "200px",
                                  margin: "5px",
                                  cursor: "pointer",
                                }}
                                src={`https://vistalk-api.bosselt.com/${item?.image}`}
                              />
                            </div>
                          </Col>
                        );
                      })}
                      {/* {profileData?.userPosts?.length == 0 &&
                        "No data available yet"} */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "250px",
                          width: "350px",
                        }}
                      >
                        <p>No Post Found</p>
                      </div>

                      {/* <Col lg={4}>
                        <div className="post-box">
                          <img src={require("../Assets/Images/image3.png")} />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="post-box">
                          <img src={require("../Assets/Images/image1.png")} />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="post-box">
                          <img src={require("../Assets/Images/image2.png")} />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="post-box">
                          <img src={require("../Assets/Images/image3.png")} />
                        </div>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </Col>

              <Col lg={6} className="mt-4">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Delete Account</h2>
                        <Figure.Caption className="m-0 mt-4">
                          <p>
                            Once you delete your account, you can not retrieve
                            the account. Please be certain.
                          </p>
                          <div
                            style={{ cursor: "pointer" }}
                            class="dlt-ac-btn mt-4"
                          >
                            <a
                              // onClick={() =>
                              //   handledelete(profileData?.userData?._id)

                              // }
                              onClick={() => setshowd(true)}
                            >
                              Delete Account
                            </a>
                          </div>
                        </Figure.Caption>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} className="mt-4">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Deactivate Account</h2>
                        <Figure.Caption className="m-0 mt-4">
                          <p>
                            You will not be able to receive messages,
                            notifications for up to 24 hours.
                          </p>
                          <div class="form-check form-switch mt-3">
                            <input
                              // onClick={() =>
                              //   handleclick(profileData?.userData?._id)
                              // }
                              onClick={handleshow}
                              checked={
                                profileData?.userData?.is_active === true
                                  ? false
                                  : true
                              }
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                            />
                          </div>
                        </Figure.Caption>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            {profileData?.userData?.is_active === false
              ? "Are You Sure You Want To Activate This Account"
              : "Are You Sure You Want To Deactivate This Account"}
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleclick}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showd} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are You Sure You Want To delete This Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handledelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      {selectedImage !== null && (
        <div className="lightbox">
          <span className="close" onClick={closeLightbox}>
            &times;
          </span>
          <img
            src={`https://vistalk-api.bosselt.com/${profileData?.userPosts[selectedImage]?.image}`}
            // alt={`Image ${selectedImage}`}
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        </div>
      )}
    </Layout>
  );
}
