import { createSlice } from "@reduxjs/toolkit";
import { dashboard } from "../actions/adminactions";



export const dashboardSlice = createSlice({
    name:"dashboard",
    initialState:{
        allData:""
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(dashboard.fulfilled,(state,action)=>{
            state.allData=action.payload
        })
    }
    
})

export default dashboardSlice.reducer