import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import {
  userVerification,
  verificationList,
} from "../redux/actions/adminactions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";

export default function UserVerification() {
  const dispatch = useDispatch();
  const [allUsers, setallUsers] = useState(null);
  const [show, setshow] = useState(false);
  const [showd, setshowd] = useState(false);
  const [pagination, setpagination] = useState(null);
  // const [uId, setUId] = useState(null);
  const [flag, setflag] = useState(true);
  const [userMgmtParams, setUserMgmtParams] = useState({
    page: "1",
    search: "",
    limit: "10",
  });

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let data = await dispatch(verificationList(userMgmtParams));
        setallUsers(data?.payload);
        setpagination(data?.payload?.totalPages);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchdata();
  }, [userMgmtParams, flag]);

  const handlesearch = (e) => {
    setUserMgmtParams((old) => ({ ...old, search: e.target.value }));
  };

  const handlePages = (page) => {
    // dispatch(alluserpgnumber(page));

    setUserMgmtParams((old) => ({ ...old, page: page }));
  };

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(
        verificationList({
          page: "",
          search: "",
          limit: "",
        })
      );
      console.log(response);

      const allData = response?.payload?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  // const handleshow = (id) => {
  //   setshow(true);
  //   setUId(id);
  // };
  // const handleshowd = (id) => {
  //   setshowd(true);
  //   setUId(id);
  // };

  const handleverification = async (uId) => {
    try {
      let data = await dispatch(userVerification({ userId: uId, type: "1" }));

      if (data?.payload?.status_code === 200) {
        toast.success(data?.payload?.message);
        console.log(data?.payload?.users?.verified_by_admin);
        setflag(!flag);
        setshow(false);
        // navigate("/UserManagement");
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleRejectverification = async (uId) => {
    try {
      let data = await dispatch(userVerification({ userId: uId, type: "0" }));
      if (data?.payload?.status_code === 200) {
        toast.success(data?.payload?.message);
        setflag(!flag);
        setshowd(false);
        // navigate("/UserManagement");
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handlechange = (e) => {
    setUserMgmtParams((old) => ({ ...old, limit: e.target.value }));
  };

  console.log(allUsers?.users?.length);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Verification</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    value={userMgmtParams.search}
                    onChange={handlesearch}
                    placeholder="Keyword Search.."
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (userMgmtParams?.page - 1) * userMgmtParams?.limit}{" "}
                -{" "}
                {allUsers?.users?.length +
                  (userMgmtParams?.page - 1) * userMgmtParams.limit}{" "}
                of {allUsers?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlechange}
                  name="limit"
                  value={userMgmtParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>User name </th>
                <th>Full Name </th>
                <th>Email</th>
                <th>Phone Number </th>
                <th>Company Name(if any) </th>
                <th>Profile Image </th>
                <th> Company Email </th>
                <th>Account Status</th>
              </tr>
            </thead>
            <tbody>
              {allUsers?.users?.map((item, index) => {
                const serialNumber =
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit +
                  index +
                  1;

                console.log(item?.verified_by_admin);
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{item?.userName || "N/A"}</td>
                    <td>{item?.fullName || "N/A"}</td>
                    <td>{item?.email || "N/A"}</td>
                    <td>{item?.countryCode} {item?.phoneNumber || "N/A"}</td>
                    <td>{item?.companyName || "N/A"}</td>
                    <td className="user-img">
                      <img
                        src={
                          item?.profileImage
                            ? `https://vistalk-api.bosselt.com/${item?.profileImage}`
                            : require("../Assets/Images/Logo.svg").default
                        }
                      />
                    </td>
                    <td>{item?.companyEmail || "N/A"}</td>

                    <td>
                      <div>
                        <Button
                          style={{ width: "120px" }}
                          onClick={() => handleverification(item?._id)}
                          className="account-btn"
                        >
                          {item?.verified_by_admin === 2
                            ? "Accepted"
                            : "Accept"}
                        </Button>{" "}
                        <Button
                          style={{ width: "120px" }}
                          onClick={() => handleRejectverification(item?._id)}
                          className="account-btn"
                        >
                          {item?.verified_by_admin === 3
                            ? "Rejected"
                            : "Reject"}
                        </Button>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {allUsers?.users?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        class="next-btn-new"
        pagination={pagination}
        paginationevent={handlePages}
      />

      {/* <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2>Are You Sure You Want To Verify This Account</h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshow(false)}>
            No
          </Button>
          <Button variant="secondary" onClick={handleverification}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showd} className="delete-popup">
        <Modal.Body>
          <h2>Are You Sure You Want To Unverify This Account</h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowd(false)}>
            No
          </Button>
          <Button variant="secondary" onClick={handleRejectverification}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Layout>
  );
}
