import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);



export default function Orders() {
  const chartdata=useSelector((state)=>state.dashboardData.allData)

  const data = {

    labels: ["Completed ", "Pending "],
    datasets: [
      {
        label: "# of Votes",
        data: [chartdata?.data?.activeUsers, chartdata?.data?.deActivatedUsers],
        backgroundColor: ["#C651FF", "#F9ECFF"],
  
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut className="gender-chart" data={data} />;
}
