import { createAsyncThunk } from "@reduxjs/toolkit";
import adminapi from "../../services/adminapi";
import { toast } from "react-toastify";

const adminLogout = createAsyncThunk("adminLogout", async () => {
  const { data } = await adminapi.post("/auth/logout");

  if (data?.success) {
    toast.success(data?.message);
    window.location.href = "/";
  } else if (!data?.success) {
    toast.error(data?.message);
  }
});

const ChangeAdminPassword = createAsyncThunk(
  "ChangePassword",
  async (details) => {
    const { data } = await adminapi.post("/auth/changePassword", details);
    data?.success ? toast.success(data?.message) : toast.error(data?.message);

    return data;
  }
);
const forgetAdminPassword = createAsyncThunk(
  "forgetAdminPassword",
  async (details) => {
    const { data } = await adminapi.post("/auth/forgetPassword", details);
    return data;
  }
);
const otpverification = createAsyncThunk("otpverification", async (details) => {
  const { data } = await adminapi.post("/auth/verifyotp", details);
  return data;
});
const resetAdminPassword = createAsyncThunk(
  "resetAdminPassword",
  async (details) => {
    const { data } = await adminapi.post("/auth/resetPassword", details);
    return data;
  }
);
const userMgmt = createAsyncThunk("userMgmt", async () => {
  const { data } = await adminapi.get(`userManagement`);
  return data;
});
const getDataByParams = createAsyncThunk("getDataByParams", async (details) => {
  const { data } = await adminapi.get(
    `userManagement?page=${details?.page}&limit=${details?.limit}&Role=${details?.role}&name=${details.search}&type=${details.type}`
  );
  return data;
});
const userProfile = createAsyncThunk("userProfile", async (details) => {
  const { data } = await adminapi.get(`/userDetails?userId=${details?.userId}`);
  return data;
});
const deleteuserProfile = createAsyncThunk(
  "deleteuserProfile",
  async (details) => {
    const { data } = await adminapi.post(`/deleteAccount`, details);
    return data;
  }
);
const deactivateUser = createAsyncThunk("deactivateUser", async (details) => {
  const { data } = await adminapi.post(`/activateDeactivateUser`, details);
  return data;
});
const banAndUnbanUser = createAsyncThunk("banAndUnbanUser", async (details) => {
  const { data } = await adminapi.post(`/banUnbanAccount `, details);
  return data;
});
const userVerification = createAsyncThunk(
  "userVerification",
  async (details) => {
    const { data } = await adminapi.post(`/verifiedUser `, details);
    return data;
  }
);
const verificationList = createAsyncThunk(
  "verificationList",
  async (details) => {
    const { data } = await adminapi.get(
      `/getUnverifiedList?page=${details?.page}&limit=${details?.limit}&name=${details.search}`
    );
    return data;
  }
);
const gropuListing = createAsyncThunk("gropuListing", async (details) => {
  const { data } = await adminapi.get(
    `/groupList?page=${details?.page}&limit=${details?.limit}&name=${details.search}`
  );
  return data;
});
const dashboard = createAsyncThunk("dashboard", async (details) => {
  const { data } = await adminapi.get(`/dashboard`);
  return data;
});
const groupDetails = createAsyncThunk("groupDetails", async (details) => {
  const { data } = await adminapi.get(`/getGroupDetails?groupId=${details}`);
  return data;
});
const deactivateAccount = createAsyncThunk(
  "deactivateAccount",
  async (details) => {
    const { data } = await adminapi.post(`/deactivateGroup`, details);

    return data;
  }
);
const notifications = createAsyncThunk("notifications", async (details) => {
  const { data } = await adminapi.get(
    `/getAllNotifications?page=${details?.page}&limit=${details?.limit}&search=${details.search}`
  );

  return data;
});
const addNotifications = createAsyncThunk(
  "addNotifications",
  async (detail) => {
    const { data } = await adminapi.post(`/addNotification  `, detail);

    return data;
  }
);
const deleteNotification = createAsyncThunk(
  "deleteNotification",
  async (detail) => {
    const { data } = await adminapi.post(`/deleteNotification`, detail);
    data?.success ? toast.success(data?.message) : toast.error(data?.message);
    return data;
  }
);
const reportsData = createAsyncThunk("reportsData", async (details) => {
  const { data } = await adminapi.get(
    `/GetReportUser?page=${details?.page}&limit=${details?.limit}&name=${details.search} `
  );

  return data;
});
const reportsDatabyId = createAsyncThunk("reportsDatabyId", async (details) => {
  const { data } = await adminapi.get(
    `/getReportedUserById?reportId=${details}`
  );

  return data;
});
const manageReport = createAsyncThunk("manageReport", async (detail) => {
  const { data } = await adminapi.post(`/manageReport`, detail);
  data?.success ? toast.success(data?.message) : toast.error(data?.message);
  return data;
});

const getGroupProducts = createAsyncThunk(
  "getGroupProducts",
  async (detail) => {
    const { data } = await adminapi.get(`/groupProducts?groupId=${detail}`);

    return data;
  }
);
const deleteProduct = createAsyncThunk("deleteProduct", async (detail) => {
  const { data } = await adminapi.post(`/deleteProduct`, detail);
  if (data?.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }

  return data;
});

const supportData = createAsyncThunk("supportData", async (details) => {
  const { data } = await adminapi.get(
    `/getSupports?page=${details?.page}&limit=${details?.limit}&search=${details.search} `
  );

  return data;
});

const changeSupportStatus = createAsyncThunk("changeSupportStatus", async (detail) => {
  const { data } = await adminapi.post(`/changeSupportStatus`, detail);
  if (data?.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }

  return data;
});

const supportChatData = createAsyncThunk("supportChatData", async (details) => {
  const { data } = await adminapi.get(
    `/getsupportchat?id=${details} `
  );

  return data;
});

export const adminchatmessages = createAsyncThunk(
  "adminchatmessages",
  async (details) => {
    const { data } = await adminapi.post(`/sendMessage`, details);
    return data;
  }
);

export const profileshare = createAsyncThunk(
  "profileshare",
  async (details) => {
    const { data } = await adminapi.get(`/postData?postId=${details}`);
    return data;
  }
);

export const createAdd = createAsyncThunk("createAdd", async (details) => {
  const { data } = await adminapi.post("/createAds ", details);

  if (data?.success) {
    toast.success(data?.message);
  } else if (!data?.success) {
    toast.error(data?.message);
  }

  return data;
});

// list add

export const addsList = createAsyncThunk("addsList", async (details) => {
  const { data } = await adminapi.get(
    `/getAllAds?page=${details?.page}&limit=${details?.limit}&title=${details.search}&date=${details.date}`
  );
  return data;
});

// delete add


export const deleteadd = createAsyncThunk("deleteadd", async (details) => {
  const { data } = await adminapi.post("/DeleteAds  ", details);

  if (data?.success) {
    toast.success(data?.message);
  } else if (!data?.success) {
    toast.error(data?.message);
  }

  return data;
});

// activate add

export const activateAds = createAsyncThunk("activateAds", async (details) => {
  const { data } = await adminapi.post("/activateAds  ", details);

  if (data?.success) {
    toast.success(data?.message);
  } else if (!data?.success) {
    toast.error(data?.message);
  }

  return data;
});








export {
  adminLogout,
  ChangeAdminPassword,
  forgetAdminPassword,
  otpverification,
  resetAdminPassword,
  userMgmt,
  getDataByParams,
  userProfile,
  deleteuserProfile,
  deactivateUser,
  banAndUnbanUser,
  verificationList,
  userVerification,
  gropuListing,
  dashboard,
  groupDetails,
  deactivateAccount,
  notifications,
  addNotifications,
  deleteNotification,
  reportsData,
  manageReport,
  reportsDatabyId,
  getGroupProducts,
  deleteProduct,
  supportData,
  changeSupportStatus,
  supportChatData
};
