import { createSlice } from "@reduxjs/toolkit";
import { changeSupportStatus, supportChatData, supportData } from "../actions/adminactions";
import { toast } from "react-toastify";




export const supportSlice=createSlice({
    name:"supportSlice",
    initialState:{
        supportList:"",
        message:""
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(supportData.fulfilled,(state,action)=>{
            if(action?.payload?.success){
                state.supportList=action.payload
            }else{
                toast.error(action?.payload?.message)
            }
        })
        builder.addCase(supportChatData.fulfilled,(state,action)=>{
            if(action?.payload?.success){
                state.message=action.payload
            }else{
                toast.error(action?.payload?.message)
            }
        })
    }
    
})