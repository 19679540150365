import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getGroupProducts } from "../redux/actions/adminactions";

export default function MerchView() {
  const data = useSelector((state) => state.groupdata.groupProducts);
  console.log(data);
  const { id } = useParams();
  const [flag,setflag] = useState(true)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroupProducts(id));
  }, [id,flag]);

  const handledelete = (id) => {
    dispatch(deleteProduct({ productId: id })).then(res=>{
      if(res?.payload?.success){
        setflag(!flag)
      }
    })
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Merch View</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link // to="/userManagement"
                  to={`/GroupView/${id}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              className="d-flex justify-content-end align-items-center"
            >
              {/* <div className="cmn-btn">
                <Link to="#">Merch</Link>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <div className="dashboard-items">
                <Row>
                  <Col xxl={12} xl={12} lg={12}>
                    <div className="dashbox-inner-wrap">
                      {/* <div className="d-flex justify-content-between align-items-center">
                  <h2>REVENUE OVERVIEW</h2>
                </div> */}
                      <Row>
                        <Col xxl={3} xl={3} lg={3}>
                          <div className="dash-inner-boxes">
                            <h6>Total Orders</h6>
                            <h4>{data?.data?.totalOrders}</h4>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3}>
                          <div className="dash-inner-boxes">
                            <h6>Total Ongoing Orders</h6>
                            <h4>{data?.data?.ongoingOrders}</h4>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3}>
                          <div className="dash-inner-boxes">
                            <h6>Total Completed Orders</h6>
                            <h4>{data?.data?.completedOrders}</h4>
                          </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3}>
                          <div className="dash-inner-boxes">
                            <h6>Total Products</h6>
                            <h4>{data?.data?.totalProducts}</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <Col lg={12} className="mt-4">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0 mb-4">
                        <h2 className="property">Products</h2>
                      </div>
                      <Row>
                        {data?.data?.groupProductData?.map((x) => {
                          return (
                            <Col lg="3">
                              <div className="products-card">
                                <img
                                  src={`https://vistalk-api.bosselt.com/${x?.productImage}`}
                                />
                                <div className="">
                                  <p className="name-product">
                                    {x?.productName}
                                  </p>
                                  <p className="price-product">$ {x?.price}</p>
                                </div>
                                <div
                                  onClick={() => handledelete(x?._id)}
                                  className="dlt-icon-pro"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="35"
                                    height="25"
                                    viewBox="0 0 35 35"
                                    fill="none"
                                  >
                                    <rect
                                      width="35"
                                      height="35"
                                      rx="17.5"
                                      fill="#F9ECFF"
                                    />
                                    <path
                                      d="M24 27H12V13H10V26.7C10 27.8 10.9 29 12 29H24C25.1 29 26 27.8 26 26.7V13H24V27ZM21 9V7H15V9H8V11H28V9H21ZM15 15V25H17V15H15ZM19 15V25H21V15H19Z"
                                      fill="#C651FF"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </Col>
                          );
                        })}

                        {/* <Col lg="3">
                          <div className="products-card">
                            <img
                              src={require("../Assets/Images/product-img.png")}
                            />
                            <div className="">
                              <p className="name-product">T-shirts</p>
                              <p className="price-product">$195.00</p>
                            </div>
                            <div className="dlt-icon-pro">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                              >
                                <rect
                                  width="35"
                                  height="35"
                                  rx="17.5"
                                  fill="#F9ECFF"
                                />
                                <path
                                  d="M24 27H12V13H10V26.7C10 27.8 10.9 29 12 29H24C25.1 29 26 27.8 26 26.7V13H24V27ZM21 9V7H15V9H8V11H28V9H21ZM15 15V25H17V15H15ZM19 15V25H21V15H19Z"
                                  fill="#C651FF"
                                />
                              </svg>
                            </div>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="products-card">
                            <img
                              src={require("../Assets/Images/product-img.png")}
                            />
                            <div className="">
                              <p className="name-product">T-shirts</p>
                              <p className="price-product">$195.00</p>
                            </div>
                            <div className="dlt-icon-pro">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                              >
                                <rect
                                  width="35"
                                  height="35"
                                  rx="17.5"
                                  fill="#F9ECFF"
                                />
                                <path
                                  d="M24 27H12V13H10V26.7C10 27.8 10.9 29 12 29H24C25.1 29 26 27.8 26 26.7V13H24V27ZM21 9V7H15V9H8V11H28V9H21ZM15 15V25H17V15H15ZM19 15V25H21V15H19Z"
                                  fill="#C651FF"
                                />
                              </svg>
                            </div>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="products-card">
                            <img
                              src={require("../Assets/Images/product-img.png")}
                            />
                            <div className="">
                              <p className="name-product">T-shirts</p>
                              <p className="price-product">$195.00</p>
                            </div>
                            <div className="dlt-icon-pro">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                              >
                                <rect
                                  width="35"
                                  height="35"
                                  rx="17.5"
                                  fill="#F9ECFF"
                                />
                                <path
                                  d="M24 27H12V13H10V26.7C10 27.8 10.9 29 12 29H24C25.1 29 26 27.8 26 26.7V13H24V27ZM21 9V7H15V9H8V11H28V9H21ZM15 15V25H17V15H15ZM19 15V25H21V15H19Z"
                                  fill="#C651FF"
                                />
                              </svg>
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
