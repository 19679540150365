import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { changeSupportStatus, supportData } from "../redux/actions/adminactions";
import Pagination from "../Components/Layout/Pagination";

export default function Support() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.supportData.supportList);
  console.log(data);

  const [userMgmtParams, setUserMgmtParams] = useState({
    page: "1",
    search: "",
    limit: "10",
  });
  const [ flag,setFlag] = useState("")

  useEffect(() => {
    dispatch(supportData(userMgmtParams));
  }, [userMgmtParams,flag]);

  const handlePages = (page) => {
    // dispatch(alluserpgnumber(page));

    setUserMgmtParams((old) => ({ ...old, page: page }));
  };

  const handlechange = (e) => {
    setUserMgmtParams((old) => ({ ...old, limit: e.target.value }));
  };

  const handlesearch = (e) => {
    setUserMgmtParams((old) => ({ ...old, search: e.target.value }));
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>User Support</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    name="search"
                    value={userMgmtParams.search}
                    onChange={handlesearch}
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="#">DOWNLOAD </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (userMgmtParams?.page - 1) * userMgmtParams?.limit}{" "}
                -{" "}
                {data?.data?.supports?.length +
                  (userMgmtParams?.page - 1) * userMgmtParams.limit}{" "}
                of {data?.data?.totalSupports} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlechange}
                  name="limit"
                  value={userMgmtParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Contact Number</th>
                <th>Message </th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.supports?.map((x,index) => {
                const serialNumber =
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit +
                  index +
                  1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      {x?.user_id?.fullName || "N/A"}
                      <br />
                      {/* <span className="time">12:03</span> */}
                    </td>
                    <td>
                      <Link to={`/Supportchat/${x?._id}`}>
                        <b>{x?.user_id?.email || "N/A"}</b>
                      </Link>
                    </td>
                    <td>{x?.user_id?.countryCode} {x?.user_id?.phoneNumber || "N/A"}</td>
                    <td>{x?.message || "N/A"}</td>
                    <td>{x?.status == 0 ? "In-progress" : "Resolved"}</td>
                    <td className="select-box">
                      <Form.Select
                       onChange={(e) => {
                          dispatch(
                            changeSupportStatus({
                              id: x?._id,
                              status: e.target.value,
                            })
                          ).then((res) => {
                            if (res?.payload?.success) {
                              setFlag(!flag);
                            }
                          });
                        }}
                        value={x?.status}
                        aria-label="Default select example"
                      >
                        <option value="1">Resolved</option>
                        <option value="0">In Progress</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.data?.supports?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        class="next-btn-new"
        pagination={data?.data?.totalPages}
        paginationevent={handlePages}
      />
    </Layout>
  );
}
