import React, { useState } from "react";
import ReactPaginate from "react-paginate";

export default function Pagination({ paginationevent, pagination }) {
  const handlePageClick = (event) => {
    paginationevent(event.selected + 1);
  };

  return (
    <div className="pagination_new">
      <ReactPaginate
        breakLabel="..."
        nextLabel={"Next"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={pagination}
        pageCount={pagination}
        previousLabel={"Previous"}
        renderOnZeroPageCount={null}
        // containerClassName={'pagination justify-content-center'}
        //   pageClassName={'page-item'}
        //   pageLinkClassName={'page-link'}
        //   previousClassName={'page-item'}
        //   previousLinkClassName={'page-link'}
        //   nextClassName={'page-item'}
        //   nextLinkClassName={'page-link'}
        //   breakClassName={'page-item'}
        //   breakLinkClassName={'page-link'}
        // activeClassName={"selected"}
        // pageClassName={(pageNumber) =>
        //   `page-item ${getPageItemClassName(pageNumber + 1)}`
        // }
      />
    </div>
  );
}
