import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import Users from "../Components/Layout/elements/ChartUsers";
import Orders from "../Components/Layout/elements/ChartOrders";
import { Figure } from "react-bootstrap";

export default function ManageMerchView() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Dashboard</h2>
        </div>
      </div>

      <Container fluid className="px-4 mt-4">
        <Row>
          <Col lg={4}>
            <div className="user-profile-main h-100 ">
              <Row>
                <Col lg={12}>
                  <Figure className="d-flex">
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src={require("../Assets/Images/user.png")}
                    />
                    <Figure.Caption>
                      <h2>Bts</h2>
                    </Figure.Caption>
                  </Figure>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={8} className="d-flex justify-content-end">
            <div className="manage-tabs">
              <Link to="#" className="active-product">
                All
              </Link>
              <Link to="#">TShirts</Link>
              <Link to="#">KeyChain</Link>
              <Link to="#">Sweater</Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              {/* <div className="d-flex justify-content-between align-items-center">
                  <h2>REVENUE OVERVIEW</h2>
                </div> */}
              <Row>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes user">
                    <h6>Total Orders</h6>
                    <h4>100 </h4>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes user">
                    <h6>Ongoing Orders </h6>
                    <h4>30 </h4>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes user">
                    <h6>Completed Orders</h6>
                    <h4>30 </h4>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="px-4">
        <div className="user-profile-main h-100 mt-4 mb-4">
          <h2 className="mb-3">Products</h2>
          <Row>
            <Col lg={3}>
              <div className="products-box">
                <img src={require("../Assets/Images/placeimage.png")} />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
                <button>
                  <img src={require("../Assets/Images/cross.svg").default} />
                </button>
              </div>
            </Col>
            <Col lg={3}>
              <div className="products-box">
                <img src={require("../Assets/Images/placeyour.png")} />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
                <button>
                  <img src={require("../Assets/Images/cross.svg").default} />
                </button>
              </div>
            </Col>
            <Col lg={3}>
              <div className="products-box">
                <img src={require("../Assets/Images/placeyourimage.png")} />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
                <button>
                  <img src={require("../Assets/Images/cross.svg").default} />
                </button>
              </div>
            </Col>
            <Col lg={3}>
              <div className="products-box">
                <img src={require("../Assets/Images/image.png")} />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
                <button>
                  <img src={require("../Assets/Images/cross.svg").default} />
                </button>
              </div>
            </Col>
            <Col lg={3}>
              <div className="products-box">
                <img src={require("../Assets/Images/placeimage.png")} />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
                <button>
                  <img src={require("../Assets/Images/cross.svg").default} />
                </button>
              </div>
            </Col>
            <Col lg={3}>
              <div className="products-box">
                <img src={require("../Assets/Images/placeimage.png")} />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
                <button>
                  <img src={require("../Assets/Images/cross.svg").default} />
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
