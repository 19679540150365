import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { gropuListing } from "../redux/actions/adminactions";
import Pagination from "../Components/Layout/Pagination";
import Logo from "../Assets/Images/Logo.svg"
import { groupPageNumber } from "../redux/reducers/GroupSlice";


export default function Groups() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.groupdata.alldata);
  const pagenumberfromredux = useSelector((state) => state.groupdata.pagenumber);
  const [pagination, setpagination] = useState(null);

  const [GroupParams, setGroupParams] = useState({
    page: pagenumberfromredux?pagenumberfromredux:"1",
    search: "",
    limit: "10",
  });

  useEffect(() => {
    dispatch(gropuListing(GroupParams));
  }, [GroupParams]);
  useEffect(() => {
    setpagination(data?.totalPages);
  }, [data]);

  const handlesearch = (e) => {
    setGroupParams((old) => ({ ...old, search: e.target.value }));
  };
  const handlePages = (page) => {
    dispatch(groupPageNumber(page));

    setGroupParams((old) => ({ ...old, page: page }));
  };
  const handlechange = (e) => {
    setGroupParams((old) => ({ ...old, limit: e.target.value }));
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Groups</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    name="search"
                    value={GroupParams.search}
                    onChange={handlesearch}
                    placeholder="Keyword Search.."
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 +
                  (GroupParams?.page - 1) * GroupParams?.limit} -{" "}
                {data?.users?.length +
                  (GroupParams?.page - 1) * GroupParams.limit}{" "}
                of {data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlechange}
                  name="limit"
                  value={GroupParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Group Name</th>
                <th>Group Bio</th>
                <th>Group Image</th>
                <th>Total Members</th>
                <th>Total community members </th>
                <th>Total Merch Products </th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.users?.map((item, index) => {
                const serialNumber =
                  (GroupParams?.page - 1) * GroupParams?.limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <Link to={`/GroupView/${item?._id}`}>
                        <b>{item?.groupName}</b>
                      </Link>
                    </td>
                    <td>{item?.groupBio}</td>
                    <td className="user-img">
                    {item?.groupImage ? 
                      <img
                        src={`https://vistalk-api.bosselt.com/${item?.groupImage}`}
                      /> : <img src={Logo}></img>}
                    </td>
                    <td>{item?.groupArtists?.length}</td>
                    <td>{item?.groupFollowers?.length}</td>
                    <td>{item?.totalProduct}</td>
                    <td>{item?.is_active == 1 ? "Active" : "In Active"}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          
          
            {data?.users?.length == 0 && (
              <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
              </div>
            )}
          
        </div>
      </Container>

      <Pagination
        class="next-btn-new"
        pagination={pagination}
        paginationevent={handlePages}
      />
    </Layout>
  );
}
