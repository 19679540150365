import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deactivateAccount, groupDetails } from "../redux/actions/adminactions";
import { toast } from "react-toastify";
import Logo from "../Assets/Images/Logo.svg";

export default function GroupView() {
  const dispatch = useDispatch();
  const groupDetail = useSelector((state) => state.groupdata.groupDetails);
  const [show, setshow] = useState(false);
  const [flag, setflag] = useState(false);

  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    dispatch(groupDetails(id));
  }, [id, flag]);

  const handleshow = () => {
    setshow(true);
  };

  const handleclick = async () => {
    dispatch(
      deactivateAccount({
        groupId: id,
      })
    ).then((res) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message);
        setshow(false);
        setflag(!flag);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  console.log(groupDetail, "hgh");
  console.log(groupDetail?.data?.SocialMedia?.length)

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Group View</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link // to="/userManagement"
                  to="/Groups"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="cmn-btn">
                <Link to={`/MerchView/${id}`}>Merch</Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <div className="group-profile">
                        {groupDetail?.data?.groupImage ? (
                          <img
                            src={`https://vistalk-api.bosselt.com/${groupDetail?.data?.groupImage}`}
                          ></img>
                        ) : (
                          <img src={Logo} />
                        )}

                        <h3 className="mt-4">
                          <span>Group Name - </span>
                          {groupDetail?.data?.groupName}
                        </h3>
                        <p>
                          {groupDetail?.data?.groupBio ||
                            "No data available yet"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6}>
                <div className="user-profile-main px-3 py-4 ">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Group Members</h2>
                        <div className="post-inner">
                          <div className="group-members-list">
                            {groupDetail?.data?.groupArtists?.map((item) => {
                              return (
                                <div className="members-list">
                                  {item?.profileImage ? (
                                    <img
                                      src={`https://vistalk-api.bosselt.com/${item?.profileImage}`}
                                    ></img>
                                  ) : (
                                    <img src={Logo}></img>
                                  )}

                                  <p>{item?.userName}</p>
                                </div>
                              );
                            })}
                            {groupDetail?.data?.groupArtists?.length == 0 && (
                              <div
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <p style={{ marginTop: "100px" }}>
                                  No member yet
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="mt-4">
          <Row>
            <Col lg={6}>
              <div className="user-profile-main property-main m-0 ">
                <h2 className="property">Deactivate Group</h2>
                <Figure.Caption className="m-0 mt-4">
                  <p>
                    You will not be able to receive messages, notifications for
                    up to 24 hours.
                  </p>
                  <div class="form-check form-switch mt-3">
                    <input
                      // onClick={() =>
                      //   handleclick(profileData?.userData?._id)
                      // }
                      onClick={handleshow}
                      checked={
                        groupDetail?.data?.is_active === 1 ? false : true
                      }
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                </Figure.Caption>
              </div>
            </Col>
            <Col lg={6}>
              <div className="user-profile-main scroll-set">
                <h2 className="property">Social Media</h2>

                <Row>
                  <Col lg={12}>
                    <div className="default-links social-form-new">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Youtube</Form.Label>

                            {groupDetail?.data?.SocialMedia?.map((x) => {
                              return (
                                <Form.Control
                                style={{marginTop:"8px"}}
                                  type="text"
                                  placeholder="Type Here"
                                  value={x?.youtube_link}
                                />
                              );
                            })}

                            {groupDetail?.data?.SocialMedia?.length==0 && <Form.Control
                                style={{marginTop:"8px"}}
                                  type="text"
                                  placeholder="Type Here"
                                  value="No data available yet"
                                /> }
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            {groupDetail?.data?.is_active === 0
              ? "Are You Sure You Want To Activate This Group"
              : "Are You Sure You Want To Deactivate This Group"}
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleclick}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      
{/* <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            {profileData?.userData?.is_active === false
              ? "Are You Sure You Want To Activate This Account"
              : "Are You Sure You Want To Deactivate This Account"}
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleclick}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Layout>
  );
}
